<template>
    <div class="reset-box">
        <h3>重置密码</h3>
        <el-steps
            class="reset-steps"
            :space="200"
            :active="active"
            finish-status="success"
            align-center
            simple
        >
            <el-step title="输入账号"></el-step>
            <el-step title="安全验证"></el-step>
            <el-step title="设置新密码"></el-step>
        </el-steps>
        <el-form
            v-show="active == 0"
            class="reset-form"
            status-icon
            :rules="accountFormRules"
            ref="accountForm"
            :model="accountForm"
        >
            <el-form-item label="账号" prop="username">
                <el-input v-model="accountForm.username" auto-complete="off">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onVerityAccount"
                    >下一步</el-button
                >
            </el-form-item>
        </el-form>

        <el-form
            v-show="active == 1"
            class="reset-form"
            status-icon
            :rules="verityCodeFormRule"
            ref="verityCodeForm"
            :model="verityCodeForm"
            label-position="top"
        >
            <el-form-item label="验证方式" prop="type">
                <el-row>
                    <el-col :span="24">
                        <el-select
                            v-model="verityCodeForm.type"
                            placeholder="请选择验证方式"
                        >
                            <el-option
                                v-for="item in showTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="验证码" prop="code" v-if="sendType == 'code'">
                <el-row type="flex" justify="space-between" :gutter="20">
                    <el-col :span="15">
                        <el-input
                            v-model="verityCodeForm.code"
                            maxlength="6"
                            placeholder="请输入验证码"
                        ></el-input>
                    </el-col>
                    <el-col :span="9">
                        <el-button
                            @click="isShowVcode = true"
                            :disabled="!isAllowSend"
                        >
                            <span>{{
                                isAllowSend
                                    ? "发送验证码"
                                    : `${verityCodeCountDown}秒后重新发送`
                            }}</span>
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-button @click="active--">上一步</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button type="primary" @click="onVerityCode"
                            >下一步</el-button
                        >
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>

        <el-form
            v-show="active == 2"
            class="reset-form"
            status-icon
            :rules="newPasswordFormRule"
            ref="newPasswordForm"
            :model="newPasswordForm"
            label-position="top"
        >
            <el-form-item label="新密码" prop="newPassword">
                <el-input
                    :type="showPassword ? '' : 'password'"
                    v-model="newPasswordForm.newPassword"
                    auto-complete="off"
                >
                    <i
                        class="el-input__icon"
                        :class="
                            showPassword
                                ? 'iconfont icon-cp-not-visable'
                                : 'iconfont icon-cp-visable'
                        "
                        slot="suffix"
                        @click="showPasswordHandler"
                    ></i>
                </el-input>
            </el-form-item>
            <el-form-item
                label="确认新密码"
                prop="confirmPassword"
                auto-complete="off"
            >
                <el-input
                    :type="showPassword ? '' : 'password'"
                    v-model="newPasswordForm.confirmPassword"
                    auto-complete="off"
                >
                    <i
                        class="el-input__icon"
                        :class="
                            showPassword
                                ? 'iconfont icon-cp-not-visable'
                                : 'iconfont icon-cp-visable'
                        "
                        slot="suffix"
                        @click="showPasswordHandler"
                    ></i>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-button @click="active = 0">上一步</el-button>
                    </el-col>
                    <el-col :span="12">
                        <el-button
                            type="primary"
                            @click="onResetNewPasswordCode"
                            >提交</el-button
                        >
                    </el-col>
                </el-row>
            </el-form-item>
        </el-form>
        <el-result
            v-show="active == 3"
            icon="success"
            title="设置新密码成功"
            subTitle=""
        >
            <template slot="extra">
                <el-button type="primary" size="medium" @click="backHandler"
                    >返回登录</el-button
                >
            </template>
        </el-result>
        <Vcode
            class="vcode-dialog"
            :show="isShowVcode"
            @success="sendCodeHandler"
            @close="isShowVcode = false"
        />
    </div>
</template>
<script>
import Vcode from "vue-puzzle-vcode";
import {
    verityAccountApi,
    sendCodeApi,
    verityCodeApi,
    resetNewPasswordApi
} from "@/api/admin/reset";
export default {
    name: "ResetPassword",
    components: {
        Vcode
    },
    computed: {
        verityCodeFormRule() {
            let res = {
                type: [
                    {
                        required: true,
                        message: "请选择验证方式",
                        trigger: "blur"
                    }
                ]
            };
            switch (this.sendType) {
                case "code":
                    Object.assign(res, {
                        code: [
                            {
                                required: true,
                                message: "请输入验证码",
                                trigger: "blur"
                            },
                            {
                                min: 6,
                                message: "长度为 6 个字符",
                                trigger: ["blur", "change"]
                            }
                        ]
                    });
                    break;
                default:
                    break;
            }
            return res;
        },
        showTypeOptions() {
            let res = [];
            for (let item of this.typeOptions) {
                if (this.accountInfo[item.exist]) {
                    let temp = this.accountInfo[item.exist];
                    let label = item.name;
                    if (item.reg) {
                        try {
                            temp = temp.replace(item.reg, "$1****$2");
                            label = `（${temp}）${label}`;
                        } catch (err) {
                            console.log(err);
                        }
                    }
                    res.push({
                        label,
                        value: item.type
                    });
                }
            }
            return res;
        },
        sendType() {
            let type = this.verityCodeForm.type;
            switch (type) {
                case 0:
                case 1:
                    return "code";
                default:
                    return "unknow";
            }
        },
        isAllowSend() {
            return this.verityCodeCountDown < 0;
        },
        sendCodeParams() {
            return Object.assign({}, this.accountInfo, this.verityCodeForm);
        },
        setNewPasswordParams() {
            return {
                id: this.accountInfo.id,
                authCode: this.authCode,
                newPassword: this.newPasswordForm.newPassword
            };
        }
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.newPasswordForm.newPassword !== "") {
                    this.$refs.newPasswordForm.validateField("confirmPassword");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.newPasswordForm.newPassword) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            active: 0,
            accountForm: {
                username: ""
            },
            accountFormRules: {
                username: [
                    {
                        required: true,
                        message: "请输入账号",
                        trigger: "blur"
                    }
                ]
            },
            accountInfo: {},
            verityCodeForm: {
                type: "",
                code: ""
            },
            typeOptions: [
                {
                    type: 0,
                    name: "密保邮箱",
                    exist: "email",
                    reg: /^(.{2}).+(.{1}@.+)$/g
                },
                {
                    type: 1,
                    name: "手机",
                    exist: "phone",
                    reg: /^(.{3}).+(.{2})$/g
                }
            ],
            verityCodeCountDown: -1,
            isShowVcode: false,
            authCode: "",
            newPasswordForm: {
                newPassword: "",
                confirmPassword: ""
            },
            newPasswordFormRule: {
                newPassword: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur"
                    },
                    {
                        min: 3,
                        max: 20,
                        message: "长度在 3 到 20 个字符",
                        trigger: "blur"
                    },
                    { validator: validatePass, trigger: "blur" }
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: "请再次输入密码",
                        trigger: "blur"
                    },
                    { validator: validatePass2, trigger: "blur" }
                ]
            },
            showPassword: false
        };
    },
    methods: {
        async callVerityAccountApi() {
            return new Promise((resolve, reject) => {
                verityAccountApi(this.accountForm)
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg || "账号不存在"
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async callSendCodeApi() {
            return new Promise((resolve, reject) => {
                sendCodeApi(this.sendCodeParams)
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg || "发送失败"
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async callVerityCodeApi() {
            return new Promise((resolve, reject) => {
                verityCodeApi(this.sendCodeParams)
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg || "验证码错误"
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async callResetNewPasswordApi() {
            return new Promise((resolve, reject) => {
                resetNewPasswordApi(this.setNewPasswordParams)
                    .then(res => {
                        if (res.data.code == 0) {
                            resolve();
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg || "重置密码错误"
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        onVerityAccount() {
            this.$refs["accountForm"].validate(valid => {
                if (valid) {
                    this.callVerityAccountApi().then(data => {
                        this.$set(this, "accountInfo", data);
                        this.active++;
                    });
                } else {
                    return false;
                }
            });
        },
        sendCodeHandler() {
            this.isShowVcode = false;
            if (!this.interval) {
                this.callSendCodeApi().then(() => {
                    this.verityCodeCountDown = 60;
                    this.interval = setInterval(() => {
                        if (this.verityCodeCountDown < 0) {
                            clearInterval(this.interval);
                            this.interval = undefined;
                        } else {
                            this.verityCodeCountDown--;
                        }
                    }, 1000);
                });
            } else {
                this.$notify.warning({
                    title: "发送频繁",
                    message: "发送过于频繁，请稍后再发。"
                });
            }
        },
        onVerityCode() {
            this.$refs["verityCodeForm"].validate(valid => {
                if (valid) {
                    this.callVerityCodeApi().then(data => {
                        this.$set(this, "authCode", data);
                        this.active++;
                    });
                } else {
                    return false;
                }
            });
        },
        showPasswordHandler() {
            this.showPassword = !this.showPassword;
        },
        onResetNewPasswordCode() {
            this.$refs["verityCodeForm"].validate(valid => {
                if (valid) {
                    this.callResetNewPasswordApi().then(() => {
                        this.active++;
                    });
                } else {
                    return false;
                }
            });
        },
        backHandler() {
            this.$router.push({
                name: "登录"
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.reset-box {
    // background-image: url("../../assets/images/background/login-from.png");
    background-color: rgba($color: #fff, $alpha: 0.5);
    box-sizing: border-box;
    border-radius: 20px;
    padding: 20px;
    width: 600px;
    .reset-steps {
        background: unset;
    }
    .reset-form {
        padding: 20px 8%;
        /deep/ .el-select,
        /deep/ .el-input,
        /deep/ .el-button {
            width: 100%;
        }
    }
}
.vcode-dialog {
    z-index: 3001;
}
</style>
