import request from "@/router/axios";

export const verityAccountApi = params => {
    return request({
        url: "/api/admin/reset/getAccount",
        method: "get",
        params
    });
};

export const sendCodeApi = data => {
    return request({
        url: "/api/admin/reset/sendCode",
        method: "post",
        data
    });
};

export const verityCodeApi = data => {
    return request({
        url: "/api/admin/reset/verityCode",
        method: "post",
        data
    });
};

export const resetNewPasswordApi = data => {
    return request({
        url: "/api/admin/reset/resetNewPassword",
        method: "post",
        data
    });
};
