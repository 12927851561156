<template>
    <div class="reset-container">
        <div class="reset-main">
            <h2 class="reset-title"></h2>
            <ResetPassword />
        </div>
    </div>
</template>
<script>
import ResetPassword from './resetPassword'
export default {
    name: "Reset",
    components: {
        ResetPassword
    }
};
</script>
<style lang="scss" scoped>
.reset-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/background/login.jpeg");
    background-size: 100% 100%;
    position: relative;
    height: 100vh;
}
</style>
